define("itarp-admin-dashboard/components/slider-handle", ["exports", "@love-open-source/ember-slider/components/slider-handle"], function (_exports, _sliderHandle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _sliderHandle.default;
    }
  });
});