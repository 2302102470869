define("itarp-admin-dashboard/services/gtag", ["exports", "@onehilltech/ember-cli-google-analytics/services/gtag"], function (_exports, _gtag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _gtag.default;
    }
  });
});