define("itarp-admin-dashboard/adapters/model-application", ["exports", "itarp-model-designer/adapters/model-application"], function (_exports, _modelApplication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _modelApplication.default;
    }
  });
});