define("itarp-admin-dashboard/mirage/factories/workflow", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const tenantIds = ['1334c133-f13d-4798-9b5b-bda37e0b0ffc', '1334c133-f13d-4798-9b5b-bda37e0b0ffd', '1334c133-f13d-4798-9b5b-bda37e0b0ffe', '1334c133-f13d-4798-9b5b-bda37e0b0fff'];
  const workflows = ['Experfy TalendCloud Workflow', 'Experfy Candidates Recruiting Workflow', 'Job Opportunity Workflow', 'Job Opportunity Workflow'];
  const types = ['TalentCloud', 'Recruiting', 'Custom', 'Custom'];
  const descriptions = ['This is default workflow used by Experfy TalendClouds', 'This is default workflow used by Experfy jobs', 'This is default workflow used to manage stages of a job', 'This is default workflow used to manage stages of a job'];

  var _default = _emberCliMirage.Factory.extend({
    tenantId: i => tenantIds[i],
    name: i => workflows[i],
    type: i => 'Custom',
    template: 'Job',
    description: i => descriptions[i],
    usedBy: '0 Job Opportunities',
    isPublished: true,
    afterCreate: (workflow, server) => {
      server.createList('stage-group', 4, {
        workflow: workflow
      });
    }
  });

  _exports.default = _default;
});