define("itarp-admin-dashboard/mirage/gui-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(context) {
    context.namespace = 'routing/gui/api/';
    context.get('pages');
  }
});