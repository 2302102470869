define("itarp-admin-dashboard/serializers/ui-workflow", ["exports", "itarp-shared-assets-addon/serializers/data", "@ember-data/serializer/rest"], function (_exports, _data, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class UiWorkflowSerializer extends _data.default.extend(_rest.EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'id');

      _defineProperty(this, "attrs", {
        stages: {
          embedded: 'always'
        }
      });
    }

    normalizeFindAllResponse(store, modelClass, payload, ...rest) {
      const data = payload ? payload : [];
      data.meta = payload.meta || {};
      return super.normalizeFindAllResponse(store, modelClass, data, ...rest);
    }

    normalizeFindRecordResponse(store, modelClass, payload, id, requestType) {
      const data = {
        id: id,
        stages: Object.keys(payload).map(stageId => {
          return {
            buttons: payload[stageId].map((button, index) => {
              return {
                id: `${id}${stageId}${index}`,
                ...button
              };
            }),
            id: stageId,
            type: 'ui-stage',
            workflow: id
          };
        })
      };
      return super.normalizeFindRecordResponse(store, modelClass, data, id, requestType);
    }

  }

  _exports.default = UiWorkflowSerializer;
});