define("itarp-admin-dashboard/adapters/model-designer", ["exports", "itarp-auth-addon/adapters/ad-token-adapter"], function (_exports, _adTokenAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ModelDesignerAdapter extends _adTokenAdapter.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", 'routing/model');
    }

    pathForType(type) {
      return underscore(singularize(type));
    }

    get headers() {
      return { ...super.headers,
        Accept: 'application/json'
      };
    }

  }

  _exports.default = ModelDesignerAdapter;
});