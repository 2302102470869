define("itarp-admin-dashboard/serializers/offer", ["exports", "itarp-admin-dashboard/serializers/data"], function (_exports, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OfferSerializer extends _data.default {}

  _exports.default = OfferSerializer;
});