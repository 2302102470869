define("itarp-admin-dashboard/routes/details/sourcing-loading", ["exports", "itarp-opportunity-designer/routes/details/sourcing-loading"], function (_exports, _sourcingLoading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _sourcingLoading.default;
    }
  });
});