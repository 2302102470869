define("itarp-admin-dashboard/serializers/reason", ["exports", "@ember-data/serializer/json-api", "@ember/object/computed"], function (_exports, _jsonApi, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ReasonSerializer extends _jsonApi.default {
    normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      const reasons = payload.reasons;
      delete payload.reasons;
      return super.normalizeQueryResponse(store, primaryModelClass, {
        data: reasons
      }, id, requestType);
    }

    normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      let data = payload ? payload.reasons : [];
      data = data.map(d => {
        return {
          id: d.id,
          type: 'reason',
          attributes: {
            title: d.attributes ? d.attributes.title : ''
          }
        };
      });
      data = data.uniqBy('title');
      return super.normalizeFindAllResponse(store, primaryModelClass, {
        data
      }, id, requestType);
    }

  }

  _exports.default = ReasonSerializer;
});