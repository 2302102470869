define("itarp-admin-dashboard/presenters/cloud-applicant", ["exports", "itarp-task-dashboard/presenters/cloud-applicant"], function (_exports, _cloudApplicant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _cloudApplicant.default;
    }
  });
});