define("itarp-admin-dashboard/services/engine-base-store", ["exports", "@ember-data/store", "@ember/application"], function (_exports, _store, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class EngineBaseStore extends _store.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "engineName", void 0);
    }

    get adapter() {
      return this.engineName;
    }

    adapterFor(name) {
      if (name === 'application') {
        const owner = (0, _application.getOwner)(this);
        const engine = owner.lookup(`engine:${this.engineName}`);
        const factory = engine.resolveRegistration(`adapter:${name}`);
        console.log(factory);
        return factory ? factory.create(engine) : owner.lookup(`adapter:${this.engineName}`);
      }

      return super.adapterFor(...arguments);
    }

    serializerFor(name) {
      if (name === 'application') {
        const owner = (0, _application.getOwner)(this);
        const engine = owner.lookup(`engine:${this.engineName}`);
        const factory = engine.resolveRegistration(`serializer:${name}`);
        if (factory) return factory;
        return factory ? factory : owner.lookup(`serializer:${this.engineName}`);
      }

      return super.serializerFor(...arguments);
    }

  }

  _exports.default = EngineBaseStore;
});