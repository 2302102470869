define("itarp-admin-dashboard/components/upload-attachment", ["exports", "itarp-opportunity-designer/components/upload-attachment"], function (_exports, _uploadAttachment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uploadAttachment.default;
    }
  });
});