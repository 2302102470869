define("itarp-admin-dashboard/mirage/models/template", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Model.extend({
    category: (0, _emberCliMirage.belongsTo)(),
    parentTemplate: (0, _emberCliMirage.belongsTo)('template', {
      inverse: 'templates'
    }),
    templates: (0, _emberCliMirage.hasMany)('template', {
      inverse: 'parentTemplate'
    })
  });

  _exports.default = _default;
});