define("itarp-admin-dashboard/helpers/opportunity/is-invited", ["exports", "itarp-opportunity-designer/helpers/opportunity/is-invited"], function (_exports, _isInvited) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isInvited.default;
    }
  });
});