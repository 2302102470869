define("itarp-admin-dashboard/mirage/scenarios/default", ["exports", "itarp-opportunity-designer/mirage/scenarios/default", "itarp-template-designer/mirage/scenarios/default", "itarp-workflow-designer/mirage/scenarios/default"], function (_exports, _default2, _default3, _default4) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    (0, _default2.default)(server);
    (0, _default3.default)(server);
    (0, _default4.default)(server);
  }
});