define("itarp-admin-dashboard/mirage/factories/stage", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const stageNames = ['Screening', 'Phone Screening', 'Predefined', 'Offer Extended', 'Predefined', 'Interview', 'Onboarding', 'Offer Accepted', 'Offer Rejected', 'Onboarding'];
  const stageTypes = ['Screening', 'Predefined', 'Assessment', 'Shortlisted', 'Interview', 'Onboarding', 'Offer', 'Screening', 'Shortlisted'];

  var _default = _emberCliMirage.Factory.extend({
    name: i => stageNames[i],
    stageType: i => stageTypes[i],
    afterCreate: (stage, server) => {
      server.createList('action', 3, {
        stage: stage
      });
    }
  });

  _exports.default = _default;
});