define("itarp-admin-dashboard/services/current-user", ["exports", "@ember/service", "@glimmer/tracking"], function (_exports, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurrentUserService = (_dec = (0, _service.inject)('ad-token-service'), _dec2 = (0, _service.inject)('store'), (_class = class CurrentUserService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "auth", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "_user", _descriptor3, this);
    }

    get user() {
      if (this._user) return this._user;
      if (!this.auth.token) return null;
      let user = this.store.peekRecord('user', this.auth.userId);

      if (user) {
        this._user = user;
        return user;
      }

      return this.store.findRecord('user', this.auth.userId).then(user => {
        this._user = user;
        return this._user;
      });
    }

    get id() {
      return this.auth.userId;
    }

    get firstName() {
      var _this$user;

      return (_this$user = this.user) === null || _this$user === void 0 ? void 0 : _this$user.firstName;
    }

    get lastName() {
      var _this$user2;

      return (_this$user2 = this.user) === null || _this$user2 === void 0 ? void 0 : _this$user2.lastName;
    }

    get email() {
      var _this$user3;

      return (_this$user3 = this.user) === null || _this$user3 === void 0 ? void 0 : _this$user3.email;
    }

    get profilePicture() {
      var _this$user4, _this$user4$profile;

      return (_this$user4 = this.user) === null || _this$user4 === void 0 ? void 0 : (_this$user4$profile = _this$user4.profile) === null || _this$user4$profile === void 0 ? void 0 : _this$user4$profile.profilePicture;
    }

    set profilePicture(value) {
      const hasRouting = value.includes('storage/show');

      if (hasRouting) {
        this.user.profile.profilePicture = value;
      } else {
        this.user.profile.profilePicture = `/opportunity/storage/show/${value}`;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_user", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class));
  _exports.default = CurrentUserService;
});