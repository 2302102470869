define("itarp-admin-dashboard/serializers/rule", ["exports", "itarp-admin-dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RuleSerializer extends _application.default {
    typeName() {
      return 'rule';
    }

    normalizeResponseForQuery(payload) {
      const result = {
        data: []
      };
      const includedData = [];
      payload.data.forEach(singleItem => {
        const relationshipsData = [];
        const ruleAttributes = singleItem.attributes;
        const ruleParametersData = ruleAttributes['rule-parameters'];
        delete ruleAttributes['rule-parameters'];
        ruleParametersData.forEach(ruleParameterData => {
          relationshipsData.push({
            id: ruleParameterData.id,
            type: 'rule-parameters'
          });
          const singleIncludedData = {
            id: ruleParameterData.id,
            type: 'rule-parameters',
            attributes: { ...ruleParameterData,
              'type-definition-id': ruleParameterData.type_definition_id
            }
          };
          delete singleIncludedData.attributes.id;
          includedData.push(singleIncludedData);
        });
        result.data.push({
          id: singleItem.id,
          type: 'rules',
          attributes: ruleAttributes,
          relationships: {
            'rule-parameters': {
              data: relationshipsData
            }
          }
        });
      });
      result.included = includedData;
      return result;
    }

  }

  _exports.default = RuleSerializer;
});