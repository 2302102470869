define("itarp-admin-dashboard/mirage/factories/taxonomies-industry", ["exports", "ember-cli-mirage", "@faker-js/faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: () => _faker.default.name.jobTitle()
  });

  _exports.default = _default;
});