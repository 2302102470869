define("itarp-admin-dashboard/serializers/business-template", ["exports", "itarp-shared-assets-addon/serializers/data"], function (_exports, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BusinessTemplateSerializer extends _data.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "primaryKey", 'id');
    }

    normalizeFindAllResponse(store, modelClass, payload, ...rest) {
      const data = payload ? payload : [];
      data.meta = payload.meta || {};
      return super.normalizeFindAllResponse(store, modelClass, {
        records: data
      }, ...rest);
    }

    normalizeQueryResponse(store, modelClass, payload, ...rest) {
      const data = payload ? payload : [];
      data.meta = payload.meta || {};
      return super.normalizeQueryResponse(store, modelClass, {
        records: data
      }, ...rest);
    }

  }

  _exports.default = BusinessTemplateSerializer;
});