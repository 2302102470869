define("itarp-admin-dashboard/mirage/endpoints/opportunity-designer", ["exports", "itarp-opportunity-designer/mirage/endpoints/opportunities", "itarp-opportunity-designer/mirage/endpoints/taxonomies-industries", "itarp-opportunity-designer/mirage/endpoints/taxonomies-categories", "itarp-admin-dashboard/mirage/helpers/endpoints"], function (_exports, _opportunities, _taxonomiesIndustries, _taxonomiesCategories, _endpoints) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _endpoints.default)(function (context) {
    context.namespace = '/opportunity/data_service';
    (0, _opportunities.default)(context);
    (0, _taxonomiesIndustries.default)(context);
    (0, _taxonomiesCategories.default)(context);
  });

  _exports.default = _default;
});