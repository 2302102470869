define("itarp-admin-dashboard/mirage/factories/category", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const tenantIds = ['1334c133-f13d-4798-9b5b-bda37e0b0ffc', '1334c133-f13d-4798-9b5b-bda37e0b0ffc'];
  const categories = ['Opportunity Posting and Approval', 'New Application Confirmation', 'When a candidate is invited to an opportunity (Hit an Invite Button)', 'Candidate accepts the invitation and applies to the opportunity'];
  const categoryTypes = ['opportunity', 'talent_cloud', 'opportunity', 'talent_cloud'];

  var _default = _emberCliMirage.Factory.extend({
    tenantId: i => tenantIds[i],
    name: i => categories[i],
    categoryType: i => categoryTypes[i],
    afterCreate: (category, server) => {
      server.createList('template', 1, 'withTemplates', {
        category: category
      });
    }
  });

  _exports.default = _default;
});