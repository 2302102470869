define("itarp-admin-dashboard/mirage/factories/template-setting", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const templateSettings = ['Experfy', 'Deloitte', 'Vodafone'];

  var _default = _emberCliMirage.Factory.extend({
    tenantId: i => i + 1,
    name: i => templateSettings[i],
    header_html: i => `<h1>${templateSettings[i]}</h1>`,
    header_text: i => templateSettings[i],
    footer_html: i => `<h1>${templateSettings[i]}</h1>`,
    footer_text: i => templateSettings[i],
    isPublished: true,
    afterCreate: (templateSetting, server) => {
      server.createList('category', 1, {
        templateSetting: templateSetting
      });
      server.createList('workflow', 1, {
        templateSetting: templateSetting
      });
    }
  });

  _exports.default = _default;
});