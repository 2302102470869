define("itarp-admin-dashboard/mirage/serializers/action-group", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ActionGroupSerializer extends _emberCliMirage.JSONAPISerializer {}

  _exports.default = ActionGroupSerializer;
});