define("itarp-admin-dashboard/controllers/authenticated/model-management/update", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/utils", "@ember/service"], function (_exports, _controller, _object, _tracking, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AuthenticatedModelManagementUpdateController = (_dec = (0, _service.inject)('model-designer-store'), (_class = class AuthenticatedModelManagementUpdateController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "modelDesignerStore", _descriptor, this);

      _initializerDefineProperty(this, "formMetaModelAttribute", _descriptor2, this);

      _initializerDefineProperty(this, "formTypeDefinition", _descriptor3, this);

      _initializerDefineProperty(this, "fieldErrors", _descriptor4, this);

      _initializerDefineProperty(this, "errors", _descriptor5, this);
    }

    validateFields() {
      this.validateDuplicate(this.formMetaModelAttribute);
      this.validateNotEmpty(this.formMetaModelAttribute.name, 'name');
      this.validateNotEmpty(this.formTypeDefinition.typeDefinition, 'type');
      this.validateRelationship(this.formTypeDefinition);

      if ((0, _utils.isEmpty)(this.errors)) {
        this.fieldErrors = false;
      }
    }

    validateRelationship(val) {
      if (val.typeDefinition === 'model_reference' && (0, _utils.isEmpty)(val.metaModelId)) {
        this.fieldErrors = true;

        if (!this.errors.findBy('id', 'relationship')) {
          this.errors.pushObject({
            id: 'relationship',
            message: 'Model reference attribute needs a model selected'
          });
        }
      } else {
        if (this.errors.findBy('id', 'relationship')) {
          this.errors.removeObject(this.errors.findBy('id', 'relationship'));
        }
      }
    }

    validateDuplicate(val) {
      if (this.model.metaModel.metaModelAttributes.isAny('name', val.name.trim())) {
        this.fieldErrors = true;

        if (!this.errors.findBy('id', 'uniq_name')) {
          this.errors.pushObject({
            id: 'uniq_name',
            message: 'Attribute name must be unique'
          });
        }
      } else {
        if (this.errors.findBy('id', 'uniq_name')) {
          this.errors.removeObject(this.errors.findBy('id', 'uniq_name'));
        }
      }
    }

    validateNotEmpty(val, attributeName) {
      if ((0, _utils.isEmpty)(val)) {
        this.fieldErrors = true;

        if (!this.errors.findBy('id', 'empty_' + attributeName)) {
          this.errors.pushObject({
            id: 'empty_' + attributeName,
            message: 'Attribute ' + attributeName + ' must be filled'
          });
        }
      } else {
        if (this.errors.findBy('id', 'empty_' + attributeName)) {
          this.errors.removeObject(this.errors.findBy('id', 'empty_' + attributeName));
        }
      }
    }

    async createNewFormRecords() {
      this.formMetaModelAttribute = await this.modelDesignerStore.createRecord('meta-model-attribute');
      this.formTypeDefinition = await this.modelDesignerStore.createRecord('type-definition');
    }

    updateMetaModel() {
      this.model.metaModel.save().then(() => {
        this.modelDesignerStore.unloadAll();
        this.transitionToRoute('authenticated.model-management');
      });
    }

    checkTypeForModelReference() {
      if (this.formTypeDefinition.typeDefinition !== 'model_reference') {
        this.formTypeDefinition.metaModelId = null;
      }
    }

    dismiss() {
      this.resetState();
    }

    resetState() {
      this.fieldErrors = false;
      this.errors.clear();
    }

    unloadRecords() {
      this.modelDesignerStore.unloadAll();
    }

    createMetaModelAttribute() {
      this.validateFields();

      if (this.fieldErrors) {
        return;
      } else {
        this.formMetaModelAttribute.metaModel = this.model.metaModel;

        if (this.formTypeDefinition.typeDefinition !== 'model_reference') {
          this.formTypeDefinition.metaModelId = null;
        }

        this.formMetaModelAttribute.typeDefinition = this.formTypeDefinition;
        this.createNewFormRecords();
      }
    }

    publish() {
      this.model.metaModel.save({
        adapterOptions: {
          publish: true
        }
      }).then(() => {
        this.model.versionStatus = 'draft';
        this.transitionToRoute('authenticated.model-management.index');
      });
    }

    get typeDefinitionOptions() {
      return [{
        id: 'number',
        name: 'Number'
      }, {
        id: 'string',
        name: 'Text'
      }, {
        id: 'integer',
        name: 'Integer'
      }, {
        id: 'float',
        name: 'Real Number'
      }, {
        id: 'date',
        name: 'Date'
      }, {
        id: 'model_reference',
        name: 'Model Reference'
      }];
    }

    get isArrayOptions() {
      return [{
        id: false,
        name: 'Single'
      }, {
        id: true,
        name: 'Collection'
      }];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modelDesignerStore", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "formMetaModelAttribute", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "formTypeDefinition", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fieldErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "errors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateMetaModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateMetaModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkTypeForModelReference", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "checkTypeForModelReference"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dismiss", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dismiss"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unloadRecords", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unloadRecords"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createMetaModelAttribute", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createMetaModelAttribute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "publish", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "publish"), _class.prototype)), _class));
  _exports.default = AuthenticatedModelManagementUpdateController;
});