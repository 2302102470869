define("itarp-admin-dashboard/serializers/meta-model-attribute", ["exports", "itarp-admin-dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MetaModelSerializer extends _application.default {
    typeName() {
      return 'meta_model_attribute';
    }

    mapAttributes(attributes) {
      return {
        name: attributes.name
      };
    }

    normalizeResponseForFindRecord(payload) {
      const result = {};
      const attributes = payload.data.attributes;
      const singleRelationData = attributes['type-definition'];
      delete attributes['type-definition'];
      const includedData = [];
      const relationshipsData = {
        id: singleRelationData.id,
        type: 'type-definition'
      };
      const singleIncludedData = {
        id: singleRelationData.id,
        type: 'type-definition',
        attributes: {
          'type-definition': singleRelationData.type_definition,
          'is-array': singleRelationData.is_array
        }
      };
      includedData.push(singleIncludedData);
      result.data = {
        id: payload.data.id,
        type: 'meta-model-attributes',
        attributes: attributes,
        relationships: {
          'type-definition': {
            data: relationshipsData
          }
        }
      };
      result.included = includedData;
      return result;
    }

  }

  _exports.default = MetaModelSerializer;
});