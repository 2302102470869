define("itarp-admin-dashboard/adapters/base", ["exports", "itarp-auth-addon/adapters/ad-token-adapter"], function (_exports, _adTokenAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BaseAdapter extends _adTokenAdapter.default {}

  _exports.default = BaseAdapter;
});