define("itarp-admin-dashboard/mirage/endpoints/navigation-config", ["exports", "itarp-admin-dashboard/mirage/helpers/endpoints", "ember-cli-mirage"], function (_exports, _endpoints, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _endpoints.default)(function (context) {
    context.namespace = '/config/manager/';
    context.get('navigation/:id', function (req, res) {
      return new _emberCliMirage.Response(200, {}, {
        sidebar: items.slice(0, 3),
        topbar: items.slice(0, 4)
      });
    });
  });

  _exports.default = _default;
  const items = [{
    label: 'TalentClouds',
    icon: 'for-talentclouds',
    items: [{
      label: 'Create a Cloud'
    }, {
      label: 'Browse TalentClouds'
    }, {
      label: 'My TalentCloud'
    }, {
      label: 'Candidates'
    }]
  }, {
    label: 'Opportunities',
    icon: 'for-opportunities',
    path: 'itarp-opportunity-designer',
    items: [{
      label: 'Post an Opportunity',
      path: 'itarp-opportunity-designer.opportunity.job-details'
    }, {
      label: 'Browse Opportunities',
      path: 'itarp-opportunity-designer'
    }, {
      label: 'My Opportunities'
    }, {
      label: 'Candidates'
    }, {
      label: 'Scheduled Interviews'
    }]
  }, {
    label: 'Talent',
    icon: 'for-talent',
    path: 'itarp-opportunity-designer.my-rosters',
    items: [{
      label: 'Browse Talent'
    }, {
      label: 'My Favorites'
    }, {
      label: 'My Rosters',
      path: 'itarp-opportunity-designer.my-rosters'
    }]
  }, {
    label: 'Settings',
    icon: 'for-configuration',
    items: [{
      label: 'Change Time-Zone'
    }, {
      label: 'Change Password'
    }]
  }, {
    label: 'Admin Dashboard',
    icon: 'for-dashboard'
  }, {
    label: 'Dashboard',
    icon: 'for-dashboard'
  }, {
    label: 'Dashboard',
    icon: 'for-dashboard',
    path: 'itarp-opportunity-designer'
  }, {
    label: 'Analytics',
    icon: 'for-metrics'
  }, {
    label: 'Tenant Settings',
    icon: 'for-settings'
  }, {
    label: 'Tenant Org Structure',
    icon: 'for-organization',
    items: [{
      label: 'Departments/Groups'
    }, {
      label: 'Practice Areas'
    }]
  }, {
    label: 'User Management',
    icon: 'for-permission',
    items: [{
      label: 'Users'
    }, {
      label: 'Groups'
    }, {
      label: 'Permissions'
    }]
  }, {
    label: 'Workflows',
    icon: 'for-workflows'
  }, {
    label: 'Templates',
    icon: 'for-templates',
    path: 'itarp-template-designer',
    items: [{
      label: 'Opportunity Templates'
    }, {
      label: 'TalentCloud Templates'
    }]
  }, {
    label: 'Interview Kits',
    icon: 'for-interview',
    items: [{
      label: 'Interview Scorecards'
    }, {
      label: 'Interview Guidelines'
    }]
  }, {
    label: 'TalentCloud Career Portal',
    icon: 'for-career',
    items: [{
      label: 'Career Site Pages'
    }, {
      label: 'Post a Job'
    }, {
      label: 'Edit a Job'
    }]
  }, {
    label: 'Integrations',
    icon: 'for-integrations'
  }, {
    label: 'Taxonomy',
    icon: 'for-categories'
  }, {
    label: 'Candidates',
    icon: 'for-clients',
    items: [{
      label: 'TalentCloud Candidates'
    }, {
      label: 'Opportunity Candidates'
    }]
  }];
});