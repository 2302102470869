define("itarp-admin-dashboard/templates/scorecards", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "9WwSDFRX",
    "block": "[[[41,[28,[37,1],[[30,0,[\"url\"]],[30,0,[\"scope\"]],[30,0,[\"params\"]],[30,0,[\"moduleName\"]]],null],[[[1,\"  \"],[8,[39,2],null,[[\"@url\",\"@scope\",\"@params\",\"@moduleName\"],[[30,0,[\"url\"]],[30,0,[\"scope\"]],[30,0,[\"params\"]],[30,0,[\"moduleName\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"and\",\"remote-frontend\"]]",
    "moduleName": "itarp-admin-dashboard/templates/scorecards.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});