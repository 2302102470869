define("itarp-admin-dashboard/mirage/serializers/action", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ActionSerializer extends _emberCliMirage.JSONAPISerializer {
    serialize(snapshot, options) {
      let json = _emberCliMirage.JSONAPISerializer.prototype.serialize.apply(this, arguments);

      if (snapshot.meta.actionGroup === 'Internal Communication') {
        json.meta = {
          template: snapshot.meta.template,
          actionGroup: snapshot.meta.actionGroup,
          name: snapshot.name,
          subject: snapshot.subject,
          message: snapshot.message,
          emailText: snapshot.emailText
        };
      }

      if (snapshot.meta.actionGroup === 'Task') {
        json.meta = {
          actionGroup: snapshot.meta.actionGroup,
          name: snapshot.name,
          taskName: snapshot.subject,
          description: snapshot.message
        };
      } // delete json.data.attributes.meta;


      return json;
    }

  }

  _exports.default = ActionSerializer;
});