define("itarp-admin-dashboard/mirage/config", ["exports", "itarp-admin-dashboard/mirage/gui-config", "itarp-admin-dashboard/mirage/endpoints/opportunity-designer", "itarp-admin-dashboard/mirage/endpoints/template-designer", "itarp-admin-dashboard/mirage/endpoints/workflow-designer", "itarp-admin-dashboard/mirage/endpoints/navigation-config", "itarp-admin-dashboard/config/environment", "miragejs"], function (_exports, _guiConfig, _opportunityDesigner, _templateDesigner, _workflowDesigner, _navigationConfig, _environment, _miragejs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.post('/rbac/user_permissions/validate', async function (schema, request) {
      const response = await fetch(_environment.default.RBAC + '/api/v1/user_permissions/validate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: request.requestBody
      });
      const result = await response.json();
      return new _miragejs.Response(200, {
        'Content-Type': 'application/json'
      }, result);
    });
    this.passthrough('http://rbac-service.develop.experfy.com/api/v1/user_permissions/validate');
    (0, _opportunityDesigner.default)(this);
    (0, _templateDesigner.default)(this);
    (0, _guiConfig.default)(this);
    (0, _workflowDesigner.default)(this);
    (0, _navigationConfig.default)(this);
  }
});