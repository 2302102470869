define("itarp-admin-dashboard/mirage/models/stage-group", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Model.extend({
    workflow: (0, _emberCliMirage.belongsTo)(),
    stages: (0, _emberCliMirage.hasMany)()
  });

  _exports.default = _default;
});