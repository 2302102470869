define("itarp-admin-dashboard/routes/dashboard", ["exports", "itarp-admin-dashboard/routes/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardRoute extends _application.default {}

  _exports.default = DashboardRoute;
});