define("itarp-admin-dashboard/routes/tenant-dashboard/integrations", ["exports", "itarp-admin-dashboard/routes/tenant-dashboard"], function (_exports, _tenantDashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TenantDashboardIntegrationsRoute extends _tenantDashboard.default {}

  _exports.default = TenantDashboardIntegrationsRoute;
});