define("itarp-admin-dashboard/adapters/td-opportunity", ["exports", "itarp-task-dashboard/adapters/td-opportunity"], function (_exports, _tdOpportunity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _tdOpportunity.default;
    }
  });
});