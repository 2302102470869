define("itarp-admin-dashboard/templates/unauthorized", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "5EnDZUWX",
    "block": "[[[10,0],[14,0,\"component-wrapper system-error\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"system-error__image system-error__403\"],[12],[13],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"system-error__message mar-t--2\"],[12],[1,\"\\n\"],[41,[30,0,[\"isExpertOrGuest\"]],[[[1,\"      You are not authorized to perform this action.\\n\"]],[]],[[[1,\"      You currently do not have this feature under your current subscription\\n      level.\\n      \"],[10,\"br\"],[12],[13],[1,\"\\n      Please\\n      \"],[10,3],[14,6,\"https://www.experfy.com/contact\"],[12],[1,\"contact us\"],[13],[1,\"\\n      to request an upgrade.\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"system-error__assistance mar-t--1\"],[12],[1,\"\\n    Redirecting you to the dashboard in\\n    \"],[10,1],[12],[1,[30,0,[\"countDown\"]]],[13],[1,\"\\n    seconds...\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"if\"]]",
    "moduleName": "itarp-admin-dashboard/templates/unauthorized.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});