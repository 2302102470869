define("itarp-admin-dashboard/services/micro-frontend", ["exports", "@ember/service", "itarp-admin-dashboard/utils/mf-parameters", "@glimmer/tracking", "@ember/runloop"], function (_exports, _service, _mfParameters, _tracking, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const NAMESPACE = '/remote';
  const ENTRY_FILENAME = 'remoteEntry.js';

  const entryPoint = name => `${NAMESPACE}/${name}/${ENTRY_FILENAME}`;

  const SERVICES = {
    scorecards: {
      scope: 'experfy',
      url: entryPoint('scorecards')
    },
    tenant_configuration: {
      scope: 'tenant',
      url: entryPoint('tenant_configuration')
    },
    talent_clouds: {
      scope: 'cloud',
      url: entryPoint('talent_clouds')
    },
    interviews: {
      scope: 'interviews',
      url: entryPoint('interviews')
    },
    workrooms: {
      scope: 'workrooms',
      url: entryPoint('workrooms')
    }
  };
  let MicroFrontendService = (_dec = (0, _service.inject)('ad-token-service'), (_class = class MicroFrontendService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "auth", _descriptor, this);

      _initializerDefineProperty(this, "currentSettings", _descriptor2, this);
    }

    getSettings({
      name,
      path
    } = {}) {
      const SERVICE = SERVICES[name] || {};
      const endpoint = `request-${name}`;
      this.currentSettings = null;
      return (0, _runloop.later)(this, () => {
        this.currentSettings = this.configFor({
          name,
          path
        });
        return this.currentSettings;
      });
    }

    configFor({
      name,
      path
    } = {}) {
      const SERVICE = SERVICES[name] || {};
      const endpoint = `request-${name}`;
      return { ...SERVICE,
        params: this.getParams(path, endpoint)
      };
    }

    getParams(pathname, endpoint) {
      const userId = this.auth.tokenInformation.sub;
      const baseUrl = window.location.origin;
      return new _mfParameters.default({
        namespace: `${baseUrl}/${endpoint}`,
        id: userId,
        token: this.auth.token,
        pathname: pathname
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentSettings", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MicroFrontendService;
});