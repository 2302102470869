define("itarp-admin-dashboard/components/forms/select-input", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/string"], function (_exports, _component, _templateFactory, _component2, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @label}}
    <label class='input-group__label' for={{@attrName}}>{{@label}}</label>
  {{/if}}
  <PowerSelect @onChange={{this.changeValue}}
               @options={{@options}}
               @placeholder={{@placeholder}}
               @selected={{this.value}}
               @disabled={{@disabled}}
               @onBlur={{@onBlur}}
               @onFocus={{@onFocus}}
               @searchField="name"
               as |option|>
    {{option.name}}
  </PowerSelect>
  {{#each this.errors as |error|}}
    <div class="form-group-error">
      {{error.message}}
    </div>
  {{/each}}
  */
  {
    "id": "r2d06LAl",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,\"label\"],[14,0,\"input-group__label\"],[15,\"for\",[30,2]],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],null],[8,[39,1],null,[[\"@onChange\",\"@options\",\"@placeholder\",\"@selected\",\"@disabled\",\"@onBlur\",\"@onFocus\",\"@searchField\"],[[30,0,[\"changeValue\"]],[30,3],[30,4],[30,0,[\"value\"]],[30,5],[30,6],[30,7],\"name\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[30,8,[\"name\"]]],[1,\"\\n\"]],[8]]]]],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"errors\"]]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"form-group-error\"],[12],[1,\"\\n    \"],[1,[30,9,[\"message\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[9]],null]],[\"@label\",\"@attrName\",\"@options\",\"@placeholder\",\"@disabled\",\"@onBlur\",\"@onFocus\",\"option\",\"error\"],false,[\"if\",\"power-select\",\"each\",\"-track-array\"]]",
    "moduleName": "itarp-admin-dashboard/components/forms/select-input.hbs",
    "isStrictMode": false
  });

  let FormsSelectInputComponent = (_dec = (0, _object.computed)("args.model.errors"), _dec2 = (0, _object.computed)("args.model.errors.[]"), (_class = class FormsSelectInputComponent extends _component2.default {
    get hasErrors() {
      return !!this.errors;
    }

    get errors() {
      return this.args.model.errors.errorsFor((0, _string.underscore)(this.args.attrName));
    }

    get value() {
      return this.args.options.find(option => option.id === this.args.model[this.args.attrName]);
    }

    set value(val) {
      this.args.model[this.args.attrName] = val;
    }

    changeValue(nextValue) {
      this.value = nextValue.id;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "hasErrors", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "hasErrors"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "errors", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "errors"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeValue"), _class.prototype)), _class));
  _exports.default = FormsSelectInputComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormsSelectInputComponent);
});