define("itarp-admin-dashboard/mirage/endpoints/workflow-designer", ["exports", "itarp-workflow-designer/mirage/endpoints/workflows", "itarp-workflow-designer/mirage/endpoints/stage-groups", "itarp-workflow-designer/mirage/endpoints/stages", "itarp-workflow-designer/mirage/endpoints/actions", "itarp-workflow-designer/mirage/endpoints/action-groups", "itarp-workflow-designer/mirage/endpoints/action-templates", "itarp-template-designer/mirage/endpoints/fields", "itarp-template-designer/mirage/endpoints/tokens", "itarp-template-designer/mirage/endpoints/templates", "itarp-workflow-designer/mirage/endpoints/workflow-templates", "itarp-admin-dashboard/mirage/helpers/endpoints"], function (_exports, _workflows, _stageGroups, _stages, _actions, _actionGroups, _actionTemplates, _fields, _tokens, _templates, _workflowTemplates, _endpoints) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _endpoints.default)(function (context) {
    context.namespace = '/routing/workflow/api/v1';
    (0, _workflows.default)(context);
    (0, _stageGroups.default)(context);
    (0, _stages.default)(context);
    (0, _actions.default)(context);
    (0, _actionGroups.default)(context);
    (0, _actionTemplates.default)(context);
    (0, _fields.default)(context);
    (0, _tokens.default)(context);
    (0, _templates.default)(context);
    (0, _workflowTemplates.default)(context);
  });

  _exports.default = _default;
});