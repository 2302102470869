define("itarp-admin-dashboard/controllers/unauthorized", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/runloop", "@ember/object", "@ember/service"], function (_exports, _controller, _tracking, _runloop, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UnauthorizedController = (_dec = (0, _service.inject)('ad-token-service'), (_class = class UnauthorizedController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "countDown", _descriptor, this);

      _initializerDefineProperty(this, "auth", _descriptor2, this);
    }

    updateCounter() {
      (0, _runloop.later)(this, () => {
        this.countDown -= 1;

        if (this.countDown !== 0) {
          this.updateCounter();
        }
      }, 500);
    }

    get isExpertOrGuest() {
      return ['expert', 'guest_user'].includes(this.auth.userType);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "countDown", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 5;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateCounter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateCounter"), _class.prototype)), _class));
  _exports.default = UnauthorizedController;
});