define("itarp-admin-dashboard/helpers/isSelected", ["exports", "itarp-workflow-designer/helpers/isSelected"], function (_exports, _isSelected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _isSelected.default;
    }
  });
});