define("itarp-admin-dashboard/controllers/my-favorites", ["exports", "itarp-business-application/controllers/my-favorites"], function (_exports, _myFavorites) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _myFavorites.default;
    }
  });
});