define("itarp-admin-dashboard/components/download-resume", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <a href='#' {{on 'click' this.downloadResume}} ...attributes>{{yield}}</a>
  
  */
  {
    "id": "JlFP9Ebs",
    "block": "[[[11,3],[24,6,\"#\"],[17,1],[4,[38,0],[\"click\",[30,0,[\"downloadResume\"]]],null],[12],[18,2,null],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"on\",\"yield\"]]",
    "moduleName": "itarp-admin-dashboard/components/download-resume.hbs",
    "isStrictMode": false
  });

  let DownloadResumeComponent = (_class = class DownloadResumeComponent extends _component2.default {
    get resume() {
      if (this.args.resumeLink) return this.args.resumeLink;
      return this.args.user.profile.resume;
    }

    get fullName() {
      if (this.args.userFullName) return this.args.userFullName;
      return this.args.user.fullName;
    }

    downloadResume(e) {
      e.preventDefault();
      const url = btoa(this.getResumeUrl()).replaceAll('/', '-');
      const fileName = `${this.fullName.toLowerCase().replaceAll('.', '').replaceAll(' ', '_')}_resume.pdf`;
      fetch(`/api/download-resume/${url}`).then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.blob();
      }).then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
      }).catch(error => console.error('Download failed:', error));
    }

    getResumeUrl() {
      if (this.isFullUrl(this.resume)) return this.resume;
      let resumeUrl = this.resume;

      if (this.resume.includes('/download/')) {
        resumeUrl = this.resume.split('/download/')[1];
      }

      return `${window.location.origin}/opportunity/storage/download/${resumeUrl}`;
    }

    isFullUrl(url) {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "downloadResume", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "downloadResume"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getResumeUrl", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getResumeUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isFullUrl", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "isFullUrl"), _class.prototype)), _class);
  _exports.default = DownloadResumeComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DownloadResumeComponent);
});