define("itarp-admin-dashboard/adapters/meta-model", ["exports", "itarp-admin-dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MetaModelAdapter extends _application.default {
    updateRecord(store, type, snapshot) {
      var _snapshot$adapterOpti;

      if ((_snapshot$adapterOpti = snapshot.adapterOptions) !== null && _snapshot$adapterOpti !== void 0 && _snapshot$adapterOpti.publish) {
        const data = super.serialize(snapshot, {});
        const url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');
        return this.ajax(`${url}/draft`, 'POST', {
          data
        });
      }

      return super.updateRecord(...arguments);
    }

    get headers() {
      const resultHeaders = super.headers;
      resultHeaders['Content-Type'] = 'application/json';
      return resultHeaders;
    }

  }

  _exports.default = MetaModelAdapter;
});