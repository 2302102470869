define("itarp-admin-dashboard/models/message", ["exports", "itarp-opportunity-designer/models/message"], function (_exports, _message) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _message.default;
    }
  });
});