define("itarp-admin-dashboard/services/favorites", ["exports", "@ember/service", "@ember/object"], function (_exports, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FavoritesService = (_class = class FavoritesService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    async findAll(resourceType) {
      const favoriteAdapter = this.store.adapterFor('favorite');
      const {
        records
      } = await favoriteAdapter.query('favorite', {
        modelName: 'favorite'
      }, {
        resource_type: resourceType
      });
      return records;
    }

    async markAsFavorite(favoriteIds, user) {
      const favorite = this.store.createRecord('favorite');
      favorite.resourceType = 'user';
      favorite.ids = favoriteIds;
      const response = await favorite.save();
      return response;
    }

    async deleteFavorite(favoriteIds) {
      const favoriteAdapter = this.store.adapterFor('favorite');
      const response = await fetch(favoriteAdapter.urlForDeleteRecord(), {
        method: 'DELETE',
        body: JSON.stringify({
          resource_type: 'user',
          ids: favoriteIds
        }),
        dataType: 'json',
        headers: favoriteAdapter.headers
      });
      return response;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "findAll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "findAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markAsFavorite", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "markAsFavorite"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteFavorite", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteFavorite"), _class.prototype)), _class);
  _exports.default = FavoritesService;
});