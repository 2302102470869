define("itarp-admin-dashboard/mirage/endpoints/template-designer", ["exports", "itarp-template-designer/mirage/endpoints/categories", "itarp-template-designer/mirage/endpoints/fields", "itarp-template-designer/mirage/endpoints/templates", "itarp-template-designer/mirage/endpoints/tokens", "itarp-template-designer/mirage/endpoints/template-settings", "itarp-admin-dashboard/mirage/helpers/endpoints"], function (_exports, _categories, _fields, _templates, _tokens, _templateSettings, _endpoints) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _endpoints.default)(function (context) {
    context.namespace = '/template-designer/template-service/api/v1';
    (0, _categories.default)(context);
    (0, _fields.default)(context);
    (0, _templates.default)(context);
    (0, _tokens.default)(context);
    (0, _templateSettings.default)(context);
  });

  _exports.default = _default;
});