define("itarp-admin-dashboard/mirage/factories/stage-group", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const stageGroups = [{
    name: 'Applied',
    stageGroup: 'Applied',
    displayOrder: 1
  }, {
    name: 'Screening',
    stageGroup: 'Screening',
    displayOrder: 2
  }, {
    name: 'Assessment',
    stageGroup: 'Assessment',
    displayOrder: 3
  }, {
    name: 'Job Completed',
    stageGroup: 'JobCompleted',
    displayOrder: 4
  }, {
    name: 'Interview',
    stageGroup: 'Interview',
    displayOrder: 5
  }, {
    name: 'Offer',
    stageGroup: 'Offer',
    displayOrder: 6
  }, {
    name: 'Onboarding',
    stageGroup: 'Onboarding',
    displayOrder: 7
  }, {
    name: 'Hired',
    stageGroup: 'Hired',
    displayOrder: 8
  }, {
    name: 'Job Completed',
    stageGroup: 'JobCompleted',
    displayOrder: 9
  }, {
    name: 'Applied',
    stageGroup: 'Applied',
    displayOrder: 1
  }, {
    name: 'Screening',
    stageGroup: 'Screening',
    displayOrder: 2
  }, {
    name: 'Assessment',
    stageGroup: 'Assessment',
    displayOrder: 3
  }, {
    name: 'Shortlisted',
    stageGroup: 'Shortlisted',
    displayOrder: 4
  }, {
    name: 'Interview',
    stageGroup: 'Interview',
    displayOrder: 5
  }, {
    name: 'Offer',
    stageGroup: 'Offer',
    displayOrder: 6
  }, {
    name: 'Onboarding',
    stageGroup: 'Onboarding',
    displayOrder: 7
  }, {
    name: 'Hired',
    stageGroup: 'Hired',
    displayOrder: 8
  }, {
    name: 'Job Completed',
    stageGroup: 'JobCompleted',
    displayOrder: 9
  }];

  var _default = _emberCliMirage.Factory.extend({
    name: i => stageGroups[i].name,
    stageGroup: i => stageGroups[i].stageGroup,
    displayOrder: i => stageGroups[i].displayOrder,
    afterCreate: (stageGroup, server) => {
      server.createList('stage', 1, {
        stageGroup: stageGroup
      });
    }
  });

  _exports.default = _default;
});