define("itarp-admin-dashboard/mirage/factories/action", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const actionGroup = {
    actionGroup: 'Internal Communication'
  };

  var _default = _emberCliMirage.Factory.extend({
    name: 'Send Email Message',
    subject: 'Thank you for your application',
    message: 'Thank you for your application',
    emailText: 'Thank you for your application',
    icon: 'email',
    meta: actionGroup
  });

  _exports.default = _default;
});