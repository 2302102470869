define("itarp-admin-dashboard/mirage/endpoints/opportunities", ["exports", "itarp-opportunity-designer/mirage/endpoints/opportunities"], function (_exports, _opportunities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _opportunities.default;
    }
  });
});