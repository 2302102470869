define("itarp-admin-dashboard/initializers/g-analytics", ["exports", "@onehilltech/ember-cli-google-analytics/initializers/g-analytics"], function (_exports, _gAnalytics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _gAnalytics.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _gAnalytics.initialize;
    }
  });
});