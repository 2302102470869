define("itarp-admin-dashboard/routes/scorecards/home", ["exports", "itarp-admin-dashboard/routes/scorecards"], function (_exports, _scorecards) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ScorecardsHomeRoute extends _scorecards.default {}

  _exports.default = ScorecardsHomeRoute;
});