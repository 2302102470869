define("itarp-admin-dashboard/services/store", ["exports", "@ember-data/store"], function (_exports, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const dataModels = ['certification', 'education', 'experience', 'practice-area', 'taxonomy'];

  class AdminStore extends _store.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "adapter", 'base');
    }

    serializerFor(modelName) {
      const serializer = super.serializerFor(...arguments);
      const applicationSerializer = super.serializerFor('application');

      if (serializer.toString() === applicationSerializer.toString()) {
        const isDataModelWithoutSerializer = dataModels.includes(modelName);
        const dataSerializer = this.serializerFor('data');
        if (isDataModelWithoutSerializer) return dataSerializer;
      }

      return serializer;
    }

  }

  _exports.default = AdminStore;
});