define("itarp-admin-dashboard/router", ["exports", "@ember/routing/router", "itarp-admin-dashboard/config/environment"], function (_exports, _router, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends _router.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('account-settings', {
      path: 'account/settings'
    });
    this.route('dashboard', {
      path: ''
    });
    this.mount('itarp-gui-designer', {
      path: 'cms'
    });
    this.mount('itarp-model-designer', {
      path: 'models'
    });
    this.mount('itarp-opportunity-designer', {
      path: 'opportunities'
    });
    this.mount('itarp-task-dashboard', {
      path: 'task-dashboard'
    });
    this.mount('itarp-task-dashboard.talent-details', {
      path: 'talent'
    });
    this.mount('itarp-template-designer', {
      path: 'templates'
    });
    this.mount('itarp-workflow-designer', {
      path: 'workflows'
    });
    this.mount('itarp-business-application', {
      path: 'expert'
    });
    this.route('scorecards');
    this.route('tenant-dashboard', {
      path: 'tenant'
    });
    this.route('talent-clouds', {
      path: 'cloud'
    }, function () {
      this.route('browse', {
        path: '/browse/:id'
      });
    });
    this.route('interviews', function () {
      this.route('schedule-interview', {
        path: '/:id/schedule'
      });
    });
    this.route('favorites', {
      path: 'my-favorites'
    });
    this.route('workrooms');
    this.route('unauthorized');
    this.route('main', {
      path: '/*path'
    });
  });
});