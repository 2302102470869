define("itarp-admin-dashboard/services/opportunity-reasons", ["exports", "@ember/service", "@ember/object", "@glimmer/tracking", "moment"], function (_exports, _service, _object, _tracking, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OpportunityReasonsService = (_class = class OpportunityReasonsService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "isLoadingOnHoldReasons", _descriptor2, this);

      _initializerDefineProperty(this, "isLoadingCloseReasons", _descriptor3, this);

      _initializerDefineProperty(this, "isLoadingDeclinedReasons", _descriptor4, this);

      _initializerDefineProperty(this, "onHoldReasons", _descriptor5, this);

      _initializerDefineProperty(this, "closeReasons", _descriptor6, this);

      _initializerDefineProperty(this, "declinedReasons", _descriptor7, this);

      _defineProperty(this, "propertiesForStatus", {
        close: {
          loading: 'isLoadingCloseReasons',
          value: 'closeReasons',
          type: 'close_opportunity'
        },
        hold: {
          loading: 'isLoadingOnHoldReasons',
          value: 'onHoldReasons',
          type: 'hold_opportunity'
        },
        declined: {
          loading: 'isLoadingDeclinedReasons',
          value: 'declinedReasons',
          type: 'reject_opportunity'
        }
      });
    }

    getReasonsForStatus(status) {
      const properties = this.propertiesForStatus[status];
      this[properties.loading] = true;
      this[properties.value] = [];
      return this.store.query('reason', {
        reason_type: properties.type,
        active: true
      }).then(result => {
        this[properties.loading] = false;
        this[properties.value] = result.filter(r => r.active);
        return this[properties.value];
      });
    }

    async getCloseOpportunityReasons() {
      return this.getReasonsForStatus('close');
    }

    getOnHoldOpportunityReasons() {
      return this.getReasonsForStatus('hold');
    }

    getDeclinedOpportunityReasons() {
      return this.getReasonsForStatus('declined');
    }

    closeOpportunity(opportunity, reason) {
      opportunity.actionFlag = 'close';
      if (reason.message) opportunity.closeReasonMessage = reason.message;
      if (reason.reasons.length) opportunity.closeReason = reason.reasons;
      return opportunity.save();
    }

    setOpportunityOnHold(opportunity, reason) {
      opportunity.actionFlag = 'setOnHold';
      if (reason.message) opportunity.onHoldMessage = reason.message;
      if (reason.reasons.length) opportunity.onHoldReasons = reason.reasons;
      opportunity.onHoldDate = this.getCurrentDate();
      return opportunity.save();
    }

    declineOpportunity(opportunity, reason) {
      opportunity.actionFlag = 'decline';
      if (reason.message) opportunity.declineMessage = reason.message;
      if (reason.reasons.length) opportunity.declineReasons = reason.reasons;
      opportunity.declineDate = this.getCurrentDate();
      return opportunity.save();
    }

    getCurrentDate() {
      const currentDate = new Date();
      const day = currentDate.getDate();
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();
      const fullDate = `${day}-${month}-${year}`;

      const dateValue = _moment.default.utc(fullDate, 'DD-MM-YYYY').toDate();

      return dateValue;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isLoadingOnHoldReasons", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isLoadingCloseReasons", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isLoadingDeclinedReasons", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onHoldReasons", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "closeReasons", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "declinedReasons", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getReasonsForStatus", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getReasonsForStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCloseOpportunityReasons", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getCloseOpportunityReasons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getOnHoldOpportunityReasons", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getOnHoldOpportunityReasons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getDeclinedOpportunityReasons", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getDeclinedOpportunityReasons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeOpportunity", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeOpportunity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setOpportunityOnHold", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setOpportunityOnHold"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "declineOpportunity", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "declineOpportunity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCurrentDate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getCurrentDate"), _class.prototype)), _class);
  _exports.default = OpportunityReasonsService;
});