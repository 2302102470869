define("itarp-admin-dashboard/mirage/serializers/category", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CategorySerializer extends _emberCliMirage.JSONAPISerializer {
    includes(request) {
      const includes = request.queryParams.include;

      if (includes && includes.indexOf('templates') !== -1) {
        return ['templates'];
      }
    }

  }

  _exports.default = CategorySerializer;
});