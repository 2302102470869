define("itarp-admin-dashboard/serializers/template-workflow", ["exports", "itarp-template-designer/serializers/template-workflow"], function (_exports, _templateWorkflow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _templateWorkflow.default;
    }
  });
});