define("itarp-admin-dashboard/mirage/endpoints/templates", ["exports", "itarp-template-designer/mirage/endpoints/templates"], function (_exports, _templates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _templates.default;
    }
  });
});