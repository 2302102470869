define("itarp-admin-dashboard/components/topnav", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <nav class='navbar'>
    <div class='navbar__content'>
      <div class='navbar__component'>
        <div class='navbar__brand'>
          <img src='/assets/images/experfy_logo.svg' alt='Experfy' class='navbar__brand__image' />
        </div>
      </div>
      <div class='navbar__component'>
        <ul class='navbar__links'>
          <li class='navbar__links__item'>
            <a class='navbar__links__link'>
              Opportunities
            </a>
          </li>
          <li class='navbar__links__item'>
            <a class='navbar__links__link'>
              Talent
            </a>
          </li>
          <li class='navbar__links__item'>
            <a class='navbar__links__link'>
              TalentClouds
            </a>
          </li>
          <li class='navbar__links__item'>
            <a class='navbar__links__link'>
              Candidates
            </a>
          </li>
          <li class='navbar__links__item'>
            <a class='navbar__links__link'>
              Settings
            </a>
          </li>
          <li class='navbar__links__item'>
            <a class='navbar__links__link'>
              Analytics
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  */
  {
    "id": "3b7uBqeF",
    "block": "[[[10,\"nav\"],[14,0,\"navbar\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"navbar__content\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"navbar__component\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"navbar__brand\"],[12],[1,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/assets/images/experfy_logo.svg\"],[14,\"alt\",\"Experfy\"],[14,0,\"navbar__brand__image\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"navbar__component\"],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"navbar__links\"],[12],[1,\"\\n        \"],[10,\"li\"],[14,0,\"navbar__links__item\"],[12],[1,\"\\n          \"],[10,3],[14,0,\"navbar__links__link\"],[12],[1,\"\\n            Opportunities\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"li\"],[14,0,\"navbar__links__item\"],[12],[1,\"\\n          \"],[10,3],[14,0,\"navbar__links__link\"],[12],[1,\"\\n            Talent\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"li\"],[14,0,\"navbar__links__item\"],[12],[1,\"\\n          \"],[10,3],[14,0,\"navbar__links__link\"],[12],[1,\"\\n            TalentClouds\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"li\"],[14,0,\"navbar__links__item\"],[12],[1,\"\\n          \"],[10,3],[14,0,\"navbar__links__link\"],[12],[1,\"\\n            Candidates\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"li\"],[14,0,\"navbar__links__item\"],[12],[1,\"\\n          \"],[10,3],[14,0,\"navbar__links__link\"],[12],[1,\"\\n            Settings\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"li\"],[14,0,\"navbar__links__item\"],[12],[1,\"\\n          \"],[10,3],[14,0,\"navbar__links__link\"],[12],[1,\"\\n            Analytics\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "itarp-admin-dashboard/components/topnav.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});