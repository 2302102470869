define("itarp-admin-dashboard/serializers/publish-setting", ["exports", "itarp-opportunity-designer/serializers/publish-setting"], function (_exports, _publishSetting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _publishSetting.default;
    }
  });
});