define("itarp-admin-dashboard/utils/event-manager", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let EventManager = (_class = class EventManager {
    constructor() {
      _defineProperty(this, "subscriptions", {});
    }

    addEventHandler(topicName, callback) {
      this.subscriptions[topicName] ||= [];
      const topic = this.subscriptions[topicName];
      const callbackStr = callback.toString();
      const subscriber = topic.find(cb => cb.toString() === callbackStr);
      if (!subscriber) return topic.pushObject(callback);
      topic[topic.indexOf(subscriber)] = callback;
    }

    removeEventHandler(topic, callback) {
      if (!this.subscriptions[topic]) return;
      this.subscriptions[topic] = this.subscriptions[topic].filter(subscriber => subscriber !== callback);
    }

    notify(topic, ...rest) {
      const subscribers = this.subscriptions[topic];
      return subscribers && Promise.all(subscribers.map(cb => cb(...rest)));
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "addEventHandler", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addEventHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeEventHandler", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeEventHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "notify", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "notify"), _class.prototype)), _class);
  _exports.default = EventManager;
});