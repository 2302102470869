define("itarp-admin-dashboard/adapters/user", ["exports", "itarp-auth-addon/adapters/ad-token-data"], function (_exports, _adTokenData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class UserAdapter extends _adTokenData.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/opportunity/data_service');
    }

    urlForQuery(query) {
      var _query$adapterOptions;

      if (query.rosterId) {
        const {
          rosterId
        } = query;
        delete query.rosterId;

        if (query.member_only) {
          return '/roster/data_service/roster_snapshots/' + rosterId + '/users';
        }

        return '/roster/data_service/rosters/' + rosterId + '/users';
      } else if (query.adapterFlag) {
        delete query.adapterFlag;
        return '/td-routing/data_service/experts';
      } else if ((_query$adapterOptions = query.adapterOptions) !== null && _query$adapterOptions !== void 0 && _query$adapterOptions.userFavorite) {
        delete query.adapterOptions.userFavorite;
        return '/opportunity/data_service/favorites';
      }

      return super.urlForQuery(...arguments);
    }

  }

  _exports.default = UserAdapter;
});