define("itarp-admin-dashboard/utils/permission-query-list", ["exports", "itarp-shared-assets-addon/utils/permission-query-list"], function (_exports, _permissionQueryList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _permissionQueryList.default;
    }
  });
});