define("itarp-admin-dashboard/services/stage-rules", ["exports", "@ember/service", "@ember/object"], function (_exports, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StageRulesService = (_dec = (0, _service.inject)('ad-token-service'), (_class = class StageRulesService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "auth", _descriptor, this);

      _defineProperty(this, "rules", [{
        stageType: 'offer_extended',
        disabled: () => true
      }, {
        stageType: 'offer_accepted',
        disabled: () => true
      }, {
        stageType: 'offer_rejected',
        disabled: () => true
      }, {
        stageType: 'onboarding',
        disabled: currentStage => currentStage.stageType !== 'offer_accepted'
      }, {
        stageType: 'hired',
        disabled: currentStage => {
          return !['offer_accepted', 'onboarding'].includes(currentStage.stageType);
        }
      }, {
        stageType: 'job_complete',
        disabled: currentStage => currentStage.stageType !== 'hired'
      }]);
    }

    applyRules(options, currentStageId) {
      const currentStage = options.find(option => option.value === currentStageId);
      if (!currentStage) return options;

      if (currentStage.clientVisible) {
        options.filterBy('clientVisible', false).forEach(option => {
          option.disabled = true;
        });
      }

      if (this.auth.userType !== 'tod_client') return options;
      return options.map(option => {
        const rule = this.rules.find(r => r.stageType === option.stageType);
        const disabled = rule ? rule.disabled(currentStage) : false;
        return { ...option,
          disabled
        };
      }).filter(option => option.clientVisible);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "applyRules", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "applyRules"), _class.prototype)), _class));
  _exports.default = StageRulesService;
});