define("itarp-admin-dashboard/services/google-api", ["exports", "itarp-opportunity-designer/services/google-api"], function (_exports, _googleApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _googleApi.default;
    }
  });
});