define("itarp-admin-dashboard/helpers/active-class", ["exports", "@ember/component/helper", "@ember/service"], function (_exports, _helper, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ActiveClass = (_class = class ActiveClass extends _helper.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      this.setupListeners();
    }

    get activeRoute() {
      return this.router.currentRoute;
    }

    get activeRouteName() {
      if (!this.activeRoute) return '';
      return this.sanitizeRouteName(this.activeRoute.name);
    }

    get activeParams() {
      if (!this.activeRoute) return {};
      return this.activeRoute.queryParams;
    }
    /**
     * This code sets up event listeners to detect URL changes even when navigating inside microfrontends.
     * The `replaceState` and `pushState` events are custom events dispatched by the browser history,
     * which are added by wrapping the history methods in the `history-event-dispatcher.js` file.
     *
     * If you encounter issues with browser history, you may want to check the implementation in
     * `/public/assets/scripts/history-event-dispatcher.js` for potential issues.
     */


    setupListeners() {
      const locationEvents = ['replaceState', 'pushState', 'popstate'];
      locationEvents.forEach(eventName => {
        window.addEventListener(eventName, () => this.recompute());
      });
    }

    compute([navItem, className]) {
      const emptyValue = '';
      if (!navItem || !this.activeRoute) return emptyValue;
      const urlBased = navItem.path && navItem.path.includes('/');
      const isActive = urlBased ? this.compareUrl(navItem) : this.compareRoute(navItem);
      if (!className) return isActive;
      return isActive ? className : emptyValue;
    }

    compareUrl(navItem) {
      const activePath = window.location.pathname;
      const matchingPaths = navItem.path === activePath;
      const matchingParams = this.compareParams(navItem.query);
      return matchingPaths && matchingParams;
    }

    compareRoute(navItem) {
      const navItemRouteName = this.sanitizeRouteName(navItem.path);
      const matchingNames = navItemRouteName === this.activeRouteName;
      const matchingParams = this.compareParams(navItem.query);
      return matchingNames && matchingParams;
    }

    compareParams(queryParams = {}) {
      if (!queryParams || Object.keys(queryParams).length === 0) {
        return true;
      }

      return Object.keys(queryParams).every(key => {
        return queryParams[key] === this.activeParams[key];
      });
    }

    sanitizeRouteName(name) {
      const routeName = name || '';
      return routeName.replace(/\.index$/, '');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = ActiveClass;
});