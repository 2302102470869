define("itarp-admin-dashboard/models/transition-action", ["exports", "itarp-workflow-designer/models/transition-action"], function (_exports, _transitionAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _transitionAction.default;
    }
  });
});