define("itarp-admin-dashboard/routes/candidates", ["exports", "itarp-opportunity-designer/routes/candidates"], function (_exports, _candidates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _candidates.default;
    }
  });
});