define("itarp-admin-dashboard/controllers/dashboard", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardController extends _controller.default {}

  _exports.default = DashboardController;
});