define("itarp-admin-dashboard/components/toolbar", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class='toolbar'>
    <div class='toolbar__content'>
      
    </div>
  </section>
  */
  {
    "id": "v9ZsjPX+",
    "block": "[[[10,\"section\"],[14,0,\"toolbar\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"toolbar__content\"],[12],[1,\"\\n    \\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "itarp-admin-dashboard/components/toolbar.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});