define("itarp-admin-dashboard/templates/application", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ejyRWqrL",
    "block": "[[[1,[28,[35,0],[[30,0,[\"layout\",\"title\"]]],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@registerAPI\"],[[30,0,[\"registerToastComponent\"]]]],null],[1,\"\\n\\n\"],[11,0],[16,0,[29,[\"designer \",[52,[30,0,[\"layout\",\"sidebarHidden\"]],\"designer--workflow\"]]]],[4,[38,3],[[28,[37,4],[[30,0,[\"layout\",\"registerElement\"]],\"main\"],null]],null],[4,[38,5],[[28,[37,4],[[30,0,[\"layout\",\"unregisterElement\"]],\"main\"],null]],null],[12],[1,\"\\n  \"],[8,[39,6],null,[[\"@items\"],[[30,0,[\"navigation\",\"topbar\"]]]],null],[1,\"\\n\"],[41,[51,[30,0,[\"layout\",\"sidebarHidden\"]]],[[[1,\"    \"],[8,[39,8],[[4,[38,3],[[28,[37,4],[[30,0,[\"layout\",\"registerElement\"]],\"sidebar\"],null]],null],[4,[38,5],[[28,[37,4],[[30,0,[\"layout\",\"unregisterElement\"]],\"sidebar\"],null]],null]],[[\"@isCollapsed\",\"@collapse\",\"@items\"],[[30,0,[\"layout\",\"sidebarCollapsed\"]],[30,0,[\"layout\",\"collapse\"]],[30,0,[\"navigation\",\"sidebar\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[11,0],[16,0,[29,[\"offscreen-preview\\n      \",[52,[30,0,[\"layout\",\"sidebarHidden\"]],\"is-full\"],\"\\n      is-collapsed\"]]],[4,[38,3],[[28,[37,4],[[30,0,[\"layout\",\"registerElement\"]],\"offscreen\"],null]],null],[4,[38,5],[[28,[37,4],[[30,0,[\"layout\",\"unregisterElement\"]],\"offscreen\"],null]],null],[12],[13],[1,\"\\n  \"],[11,\"main\"],[16,0,[29,[\"designer__content is-expanded \",[30,0,[\"layout\",\"padding\"]]]]],[4,[38,3],[[28,[37,4],[[30,0,[\"layout\",\"registerElement\"]],\"content\"],null]],null],[4,[38,5],[[28,[37,4],[[30,0,[\"layout\",\"unregisterElement\"]],\"content\"],null]],null],[12],[1,\"\\n    \"],[46,[28,[37,10],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"ui/toast\",\"if\",\"did-insert\",\"fn\",\"will-destroy\",\"main-nav\",\"unless\",\"sidebar\",\"component\",\"-outlet\"]]",
    "moduleName": "itarp-admin-dashboard/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});