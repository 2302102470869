define("itarp-admin-dashboard/services/opportunity-designer-store", ["exports", "@ember-data/store", "@ember/application"], function (_exports, _store, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const ENGINE_NAME = 'opportunity-designer';
  const ADAPTERS = {
    user: 'opportunity-user',
    invitation: 'opportunity-invitation',
    application: 'opportunity-designer'
  };

  class OpportunityDesignerStore extends _store.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "adapter", ENGINE_NAME);
    }

    adapterFor(name) {
      const customAdapterName = ADAPTERS[name];
      if (customAdapterName) return super.adapterFor(customAdapterName);
      const applicationAdapter = super.adapterFor('application');
      const adapter = super.adapterFor(...arguments);

      if (adapter.toString() === applicationAdapter.toString()) {
        return super.adapterFor(ENGINE_NAME);
      }

      return adapter;
    }

    serializerFor() {
      const serializer = super.serializerFor(...arguments);
      const applicationSerializer = super.serializerFor('application');

      if (serializer.toString() === applicationSerializer.toString()) {
        return super.serializerFor(ENGINE_NAME);
      }

      return serializer;
    }

  }

  _exports.default = OpportunityDesignerStore;
});