define("itarp-admin-dashboard/mirage/serializers/application", ["exports", "ember-cli-mirage", "@ember/string"], function (_exports, _emberCliMirage, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Applicationerializer extends _emberCliMirage.ActiveModelSerializer {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "keyForCollection", () => 'records');
    }

    keyForAttribute(attr) {
      if (attr === 'id') {
        return '_id';
      }

      return (0, _string.underscore)(attr);
    }

  }

  _exports.default = Applicationerializer;
});