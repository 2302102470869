define("itarp-admin-dashboard/serializers/itarp-opportunity-ui", ["exports", "itarp-shared-assets-addon/serializers/data"], function (_exports, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ItarpOpportunityUiSerializer extends _data.default {}

  _exports.default = ItarpOpportunityUiSerializer;
});