define("itarp-admin-dashboard/components/forms/dynamic-rule-parameters-form", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @ruleParameters as |ruleParameter|}}
    <div class='col col--{{this.colWidth}}'>
      {{#if (eq ruleParameter.typeDefinition.typeDefinition 0)}}
        <div class='input-group'>
          <Input
            class='input-group__field'
            @type='number'
            @placeholder='{{ruleParameter.name}}'
            @value={{ruleParameter.value}}
          />
        </div>
      {{/if}}
      {{#if (eq ruleParameter.typeDefinition.typeDefinition 1)}}
        <div class='input-group'>
          <Input
            class='input-group__field'
            @type='text'
            @placeholder='{{ruleParameter.name}}'
            @value={{ruleParameter.value}}
          />
        </div>
      {{/if}}
      {{#if (eq ruleParameter.typeDefinition.typeDefinition 7)}}
        <div class='checkbox-group for-inline-form'>
          <Input
            class='checkbox-group__field'
            id={{ruleParameter.name}}
            @type='checkbox'
            @checked={{ruleParameter.value}}
          />
          <label
            for={{ruleParameter.name}}
            class='checkbox-group__label'
          >
            {{ruleParameter.name}}
          </label>
        </div>
      {{/if}}
    </div>
  {{/each}}
  */
  {
    "id": "YpuYhy2p",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"  \"],[10,0],[15,0,[29,[\"col col--\",[30,0,[\"colWidth\"]]]]],[12],[1,\"\\n\"],[41,[28,[37,3],[[30,2,[\"typeDefinition\",\"typeDefinition\"]],0],null],[[[1,\"      \"],[10,0],[14,0,\"input-group\"],[12],[1,\"\\n        \"],[8,[39,4],[[24,0,\"input-group__field\"]],[[\"@type\",\"@placeholder\",\"@value\"],[\"number\",[29,[[30,2,[\"name\"]]]],[30,2,[\"value\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,3],[[30,2,[\"typeDefinition\",\"typeDefinition\"]],1],null],[[[1,\"      \"],[10,0],[14,0,\"input-group\"],[12],[1,\"\\n        \"],[8,[39,4],[[24,0,\"input-group__field\"]],[[\"@type\",\"@placeholder\",\"@value\"],[\"text\",[29,[[30,2,[\"name\"]]]],[30,2,[\"value\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,3],[[30,2,[\"typeDefinition\",\"typeDefinition\"]],7],null],[[[1,\"      \"],[10,0],[14,0,\"checkbox-group for-inline-form\"],[12],[1,\"\\n        \"],[8,[39,4],[[24,0,\"checkbox-group__field\"],[16,1,[30,2,[\"name\"]]]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,2,[\"value\"]]]],null],[1,\"\\n        \"],[10,\"label\"],[15,\"for\",[30,2,[\"name\"]]],[14,0,\"checkbox-group__label\"],[12],[1,\"\\n          \"],[1,[30,2,[\"name\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[2]],null]],[\"@ruleParameters\",\"ruleParameter\"],false,[\"each\",\"-track-array\",\"if\",\"eq\",\"input\"]]",
    "moduleName": "itarp-admin-dashboard/components/forms/dynamic-rule-parameters-form.hbs",
    "isStrictMode": false
  });

  class DynamicRuleParametersFormComponent extends _component2.default {
    get colWidth() {
      return this.args.ruleParameters.length > 1 ? 3 : 6;
    }

  }

  _exports.default = DynamicRuleParametersFormComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DynamicRuleParametersFormComponent);
});