define("itarp-admin-dashboard/serializers/rule-parameter", ["exports", "itarp-admin-dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RuleParameterSerializer extends _application.default {}

  _exports.default = RuleParameterSerializer;
});