define("itarp-admin-dashboard/components/collections/tabs", ["exports", "itarp-gui-designer/components/collections/tabs"], function (_exports, _tabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _tabs.default;
    }
  });
});