define("itarp-admin-dashboard/services/markup-rate", ["exports", "@ember/service", "@ember/object"], function (_exports, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MarkupRateService = (_dec = (0, _service.inject)('ad-token-service'), (_class = class MarkupRateService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "auth", _descriptor, this);

      _defineProperty(this, "namespace", '/rate-calculator/api/v1');
    }

    get reqHeaders() {
      return {
        Authorization: `Bearer ${this.auth.token}`,
        'Content-Type': 'application/json'
      };
    }

    request(endpoint, options) {
      return fetch(endpoint, options).then(response => {
        return response.json();
      });
    }

    getApplicationRates(applications) {
      return this.calculateApplicationRates(applications).then(response => {
        if (response.error) return;

        for (const id in response) {
          const application = applications.findBy('id', id);
          application.set('displayRate', response[id]['display_max_price']);
          application.set('markupRate', response[id]['rate']);
        }

        return Object.values(response).mapBy('display_max_price');
      });
    }

    getApplicationRate(application) {
      const endpoint = `${this.namespace}/markup_rates`;
      const query = `filter[resource_id]=${application.id}&filter[resource_type]=application`;
      return this.request(`${endpoint}?${query}`, {
        headers: this.reqHeaders
      });
    }

    calculateApplicationRates(applications) {
      const endpoint = `${this.namespace}/calculated_markup_rates/applications`;
      const payload = {
        calculated_markup_rates: {
          application_ids: applications.mapBy('id')
        }
      };
      const options = {
        method: 'POST',
        headers: this.reqHeaders,
        body: JSON.stringify(payload)
      };
      return this.request(endpoint, options);
    }

    getOpportunityRates(opportunity) {
      const endpoint = `${this.namespace}/markup_rates`;
      const query = `filter[resource_id]=${opportunity.id}&filter[resource_type]=opportunity`;
      return this.request(`${endpoint}?${query}`, {
        headers: this.reqHeaders
      });
    }

    createRates(rates) {
      const endpoint = `${this.namespace}/markup_rates`;
      const result = [];

      for (const rate of rates) {
        const payload = {
          markup_rate: rate
        };
        const options = {
          method: 'POST',
          headers: this.reqHeaders,
          body: JSON.stringify(payload)
        };
        result.push(this.request(endpoint, options));
      }

      return Promise.all(result);
    }

    updateRates(rates) {
      const endpoint = `${this.namespace}/markup_rates`;
      const result = [];

      for (const rate of rates) {
        const payload = {
          markup_rate: rate
        };
        const options = {
          method: 'PATCH',
          headers: this.reqHeaders,
          body: JSON.stringify(payload)
        };
        result.push(this.request(`${endpoint}/${rate.id}`, options));
      }

      return Promise.all(result);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getApplicationRates", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getApplicationRates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getApplicationRate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getApplicationRate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "calculateApplicationRates", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "calculateApplicationRates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getOpportunityRates", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getOpportunityRates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createRates", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createRates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateRates", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateRates"), _class.prototype)), _class));
  _exports.default = MarkupRateService;
});