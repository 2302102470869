define("itarp-admin-dashboard/utils/mf-parameters", ["exports", "itarp-admin-dashboard/utils/event-manager"], function (_exports, _eventManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MicroFrontendParameters extends _eventManager.default {
    constructor({
      id,
      token,
      pathname,
      namespace
    } = {}) {
      super(...arguments);

      _defineProperty(this, "id", '');

      _defineProperty(this, "token", '');

      _defineProperty(this, "pathname", '');

      _defineProperty(this, "namespace", '');

      _defineProperty(this, "userId", '');

      this.id = id;
      this.userId = id;
      this.token = token;
      this.pathname = pathname;
      this.namespace = namespace;
    }

    add(params) {
      for (const key in params) {
        this[key] = params[key];
      }
    }

  }

  _exports.default = MicroFrontendParameters;
});