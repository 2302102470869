define("itarp-admin-dashboard/controllers/authenticated/model-management/create", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AuthenticatedModelManagementCreateController = (_class = class AuthenticatedModelManagementCreateController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fieldErrors", _descriptor, this);

      _initializerDefineProperty(this, "errors", _descriptor2, this);
    }

    failure(reason) {
      if (reason.errors) {
        this.fieldErrors = true;

        if (!this.errors.findBy("id", "title")) {
          this.errors.pushObject({
            id: 'title',
            message: reason.errors[0].title + ". Please make sure your model title is unique and not empty"
          });
        }
      } else {
        if (this.errors.findBy("id", "title")) {
          this.errors.removeObject(this.errors.findBy("id", "title"));
        }
      }
    }

    dismiss() {
      this.resetState();
    }

    resetState() {
      this.fieldErrors = false;
      this.errors.clear();
    }

    createMetaModel() {
      const name = this.model.name.trim();
      this.model.name = name;
      this.model.save().then(() => {
        this.resetState();
        this.transitionToRoute('authenticated.model-management.index');
      }).catch(e => this.failure(e));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fieldErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "errors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "dismiss", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dismiss"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createMetaModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createMetaModel"), _class.prototype)), _class);
  _exports.default = AuthenticatedModelManagementCreateController;
});