define("itarp-admin-dashboard/mirage/factories/template", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const templates = ['Opportunity is approved', 'New application received', 'New opportunity application acknowledgement to Candidate', 'Invitation to apply for an Opportunity', 'Opportunity is approved', 'New application received', 'New opportunity application acknowledgement to Candidate', 'Invitation to apply for an Opportunity'];
  const descriptions = ['Opportunity Approver when a new opportunity is posted', 'Opportunity Approver when an new application is received', 'Opportunity Approver when a new opportunity is posted', 'the Expert when they are invited to apply for an opportunity', 'Opportunity Approver when a new opportunity is posted', 'Opportunity Approver when an new application is received', 'Opportunity Approver when a new opportunity is posted', 'the Expert when they are invited to apply for an opportunity'];
  const templateText = 'Congratulations on your new Opportunity, Senior Data Analyst! My name is John Smith and I will be your point of contact at Experfy during the onboarding process, as well as during your engagement with Deloitte';

  var _default = _emberCliMirage.Factory.extend({
    name: i => templates[i],
    description: i => 'A notification is sent to ' + descriptions[i],
    isPublished: true,
    isVisible: true,
    emailSubject: 'Congratulations',
    emailBody: templateText,
    emailText: templateText,
    smsMessage: templateText,
    inAppTitle: 'Congratulations',
    inAppMessage: templateText,
    pushMessage: templateText,
    order: i => i + 1,
    withTemplates: (0, _emberCliMirage.trait)({
      afterCreate(template, server) {
        server.create('template', {
          parentTemplate: template,
          categoryId: template.categoryId,
          metadata: {
            name: 'Experfy TalendCloud Workflow - Applied'
          }
        });
        server.create('template', {
          parentTemplate: template,
          categoryId: template.categoryId,
          metadata: {
            name: 'Experfy Candidates Recruiting Workflow - Approved'
          }
        });
      }

    })
  });

  _exports.default = _default;
});