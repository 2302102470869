define("itarp-admin-dashboard/serializers/company", ["exports", "itarp-shared-assets-addon/serializers/data"], function (_exports, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CompanySerializer extends _data.default {
    normalize(modelClass, resourceHash) {
      if (resourceHash.records) {
        return super.normalize(modelClass, resourceHash.records[0]);
      }

      return super.normalize(modelClass, resourceHash);
    }

  }

  _exports.default = CompanySerializer;
});