define("itarp-admin-dashboard/helpers/type-definition", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function typeDefinition(args) {
    const [typeDefinitionValue] = args;
    const typeDefinitionMap = {
      "number": 'Number',
      "string": 'Text',
      "integer": 'Integer',
      "float": 'Real Number',
      "date": 'Date',
      "model_reference": "Model Reference"
    };
    return typeDefinitionMap[typeDefinitionValue];
  }

  var _default = (0, _helper.helper)(typeDefinition);

  _exports.default = _default;
});