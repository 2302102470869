define("itarp-admin-dashboard/adapters/favorite", ["exports", "itarp-auth-addon/adapters/ad-token-data"], function (_exports, _adTokenData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class FavoriteAdapter extends _adTokenData.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/opportunity/data_service');
    }

    get headers() {
      const result = super.headers;
      return result;
    }

    handleResponse(status, headers, payload) {
      if (!this.isSuccess(status, headers, payload)) {
        return payload.errors ? `${payload.errors.charAt(0).toUpperCase()}${payload.errors.slice(1)}` : '';
      }

      return super.handleResponse(...arguments);
    }

    urlForUpdateRecord() {
      return `${this.namespace}/favorites`;
    }

    urlForCreateRecord() {
      return `${this.namespace}/favorites`;
    }

    urlForDeleteRecord(id, modelName, snapshot) {
      return `${this.namespace}/favorites`;
    }

    query(store, type, query) {
      const queryParams = new URLSearchParams(query).toString();
      const url = `${this.buildURL(type.modelName, null, null, 'query')}?${queryParams}`;
      const options = this.ajaxOptions(url, 'GET');
      return this.ajax(url, 'GET', options);
    }

  }

  _exports.default = FavoriteAdapter;
});