define("itarp-admin-dashboard/mirage/helpers/endpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(callback) {
    return function (context) {
      const namespace = context.namespace;
      callback(context);
      context.namespace = namespace;
    };
  }
});