define("itarp-admin-dashboard/controllers/authenticated/model-management/meta-model-attribute-update", ["exports", "@ember/object", "@ember/controller", "@ember/service"], function (_exports, _object, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AuthenticatedModelManagementMetaModelAttributeUpdateController = (_dec = (0, _service.inject)('model-designer-store'), _dec2 = (0, _object.computed)('model.addRuleToAttributeProjection.{ruleParameters,selectedRuleId}', 'model.rules'), (_class = class AuthenticatedModelManagementMetaModelAttributeUpdateController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "modelDesignerStore", _descriptor, this);
    }

    updateMetaModelAttribute() {}

    createMetaModelAttribute() {}

    get ruleOptions() {
      return this.model.rules.toArray().map(rule => {
        return {
          id: rule.id,
          name: rule.name
        };
      });
    }

    get updateProjectionRuleParameters() {
      if (this.model.addRuleToAttributeProjection.selectedRuleId) {
        (0, _object.set)(this, 'model.addRuleToAttributeProjection.ruleParameters', this.model.rules.toArray().find(rule => {
          return rule.id === this.model.addRuleToAttributeProjection.selectedRuleId;
        }).ruleParameters);
        this.model.addRuleToAttributeProjection.ruleParameters.forEach(ruleParameter => {
          ruleParameter.typeDefinition = this.modelDesignerStore.peekRecord('type-definition', ruleParameter.type_definition_id);
        });
        return this.model.addRuleToAttributeProjection.ruleParameters;
      } else {
        (0, _object.set)(this, 'model.addRuleToAttributeProjection.ruleParameters', []);
        return this.model.addRuleToAttributeProjection.ruleParameters;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modelDesignerStore", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateMetaModelAttribute", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateMetaModelAttribute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createMetaModelAttribute", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createMetaModelAttribute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateProjectionRuleParameters", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateProjectionRuleParameters"), _class.prototype)), _class));
  _exports.default = AuthenticatedModelManagementMetaModelAttributeUpdateController;
});