define("itarp-admin-dashboard/models/action-group", ["exports", "itarp-workflow-designer/models/action-group"], function (_exports, _actionGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _actionGroup.default;
    }
  });
});