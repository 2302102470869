define("itarp-admin-dashboard/serializers/type-definition", ["exports", "itarp-admin-dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TypeDefinitionSerializer extends _application.default {
    normalizeResponseForFindRecord(payload) {
      return payload;
    }

  }

  _exports.default = TypeDefinitionSerializer;
});