define("itarp-admin-dashboard/mirage/serializers/template-setting", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TemplateSettingSerializer extends _emberCliMirage.JSONAPISerializer {}

  _exports.default = TemplateSettingSerializer;
});