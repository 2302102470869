define("itarp-admin-dashboard/components/applicant-tabs/invite", ["exports", "itarp-expert-preview/components/applicant-tabs/invite"], function (_exports, _invite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _invite.default;
    }
  });
});