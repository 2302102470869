define("itarp-admin-dashboard/templates/components/draggable-object-target", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "KSIlBGzt",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,3],[24,6,\"#\"],[4,[38,1],[[30,0,[\"acceptForDrop\"]]],null],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,2,null],[1,\"\\n\"]],[]]]],[\"@enableClicking\",\"&default\"],false,[\"if\",\"fn\",\"yield\"]]",
    "moduleName": "itarp-admin-dashboard/templates/components/draggable-object-target.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});