define("itarp-admin-dashboard/services/placeholders", ["exports", "itarp-template-designer/services/placeholders"], function (_exports, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _placeholders.default;
    }
  });
});