define("itarp-admin-dashboard/templates/chat", ["exports", "itarp-opportunity-designer/templates/chat"], function (_exports, _chat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _chat.default;
    }
  });
});